import { Global, css } from "@emotion/react";

import colors from "@variables/colors";
import fontStyle from "@variables/font-style";
import layout from "@variables/layout";

import normalize from "./utilities/normalize";

const Globals = () => {
  return (
    <Global
      styles={css`
        ${normalize}
        body {
          font-family: ${fontStyle.family.montserrat};
          color: ${colors.black};
          &.stop-scroll {
            overflow: hidden;
          }
          .Toastify__toast {
            background: none !important;
            box-shadow: none !important;
            padding: 0;
          }
          .Toastify__toast-container {
            padding: 0;
            left: auto;
            right: 0;
            ${layout.mediaBreakpointDown("md")} {
              width: fit-content;
              bottom: 60px;
            }
          }
        }
      `}
    />
  );
};

export default Globals;
