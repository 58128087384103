import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import border from "@variables/border";
import colors from "@variables/colors";
import fontSize from "@variables/font-size";
import fontStyle from "@variables/font-style";

import ValidationError from "./Form/ValidationError";
import Icon from "./Icon";

type size = "small" | "normal" | "large";

const inputSizes: { [key in size]: string } = {
  small: fontSize[1],
  normal: fontSize[3],
  large: fontSize[5],
};

interface Props {
  id?: any;
  label?: any;
  onBlur?: any;
  onFocus?: any;
  type?: any;
  refs?: any;
  className?: any;
  value?: any;
  size?: any;
  withborder?: boolean;
  onChange?: any;
  onClick?: any;
  onKeyDown?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  error?: string;
  onKeyUp?: any;
  css?: any;
  step?: any;
  min?: number;
  max?: number;
}

const FloatingLabelInput = ({
  id,
  label,
  value: initialValue,
  onBlur,
  onFocus,
  type,
  refs,
  className,
  size,
  onChange,
  withborder = false,
  disabled,
  name,
  error,
  onKeyUp,
  min = 0,
  max,
  step,
  ...otherProps
}: Props) => {
  const [active, setActive] = useState(initialValue && initialValue.length > 0);
  const [value, setValue] = useState(initialValue);

  const ref = useRef(refs);

  const handleClick = () => {
    ref.current.focus();
  };

  useEffect(() => {
    setValue(initialValue);
    setActive(!!initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (type === "number" && !initialValue) {
      setValue(0);
    }
  }, []);

  const handeleOnFocus = (event: any) => {
    setActive(true);
    if (onFocus && !disabled) {
      onFocus(event);
    }
  };

  const handleOnBlur = (event: any) => {
    setActive(event.target.value.length !== 0);
    if (onBlur && !disabled) {
      onBlur(event);
    }
  };

  const handleChange = (e: any) => {
    onChange && onChange(e);
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <FloatingLabelInputContainer
        error={!!error}
        onClick={handleClick}
        className={className}
        withBorder={withborder}
        active={active}
        disabled={disabled}
        size={size}
      >
        <FloatingLabel htmlFor={id} active={active} size={size}>
          {label}
        </FloatingLabel>
        <FloatingInput
          onKeyUp={onKeyUp}
          size={size}
          active={active}
          id={id}
          onBlur={handleOnBlur}
          onFocus={handeleOnFocus}
          ref={ref}
          type={type}
          onChange={handleChange}
          value={initialValue}
          disabled={disabled}
          name={name}
          min={min}
          max={max}
          step={step}
          {...otherProps}
        />
        {type === "number" && step && (
          <NumberArrows>
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                max
                  ? onChange &&
                    onChange({
                      target: {
                        name,
                        value: value < max ? parseInt(value) + min : max,
                      },
                    })
                  : onChange &&
                    onChange({
                      target: { name, value: parseInt(value) + min },
                    });
              }}
            >
              <Icon name="chevronUp" size="tiny" />
            </button>
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                min
                  ? onChange &&
                    onChange({
                      target: {
                        name,
                        value: value > min ? parseInt(value) - min : min,
                      },
                    })
                  : onChange({
                      target: { name, value: parseInt(value) - min },
                    });
              }}
            >
              <Icon name="chevronDown" size="tiny" />
            </button>
          </NumberArrows>
        )}
      </FloatingLabelInputContainer>
      {error && <ValidationError message={error} />}
    </div>
  );
};

export default FloatingLabelInput;

const FloatingLabelInputContainer = styled.div<{
  withBorder?: boolean;
  active?: boolean;
  disabled?: boolean;
  size?: size;
  error: boolean;
}>`
  font-size: ${(props) => inputSizes[props.size || "normal"]};
  cursor: pointer;
  border: ${(props) =>
    props.withBorder ? `${border.width[2]} solid ${colors.gray[200]}` : "none"};
  position: ${(props) => props.withBorder && "relative"};
  padding: ${(props) => props.withBorder && "1em"};
  border-radius: ${border.radius[4]};
  border-color: ${(props) => props.error && colors.red[500]};
  background: ${(props) => props.disabled && colors.gray[50]};

  &:focus-within {
    border-color: ${colors.blue[500]};
  }
`;

const FloatingLabel = styled.label<{ active?: boolean; size?: size }>`
  text-transform: capitalize;
  line-height: 1;
  display: block;
  padding: 2px;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${(props) => (props.active ? colors.blue[500] : colors.gray[150])};
  background: ${(props) =>
    props.active ? colors.white : "transparent"} !important;
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  cursor: text;
  pointer-events: none;
  font-family: ${fontStyle.family["montserrat"]};
  top: ${(props) => (props.active ? "-0.6em" : "1.2em")};
  width: fit-content !important;
  height: fit-content;
  font-size: calc(${(props) => inputSizes[props.size || "normal"]});
`;

const FloatingInput = styled.input<{
  active?: boolean;
  size?: size;
}>`
  width: 100%;
  font-size: ${(props) => inputSizes[props.size || "normal"]};
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: ${colors.black};
  background: transparent;
  line-height: 1;
  text-align: left;
  text-align: -webkit-left;

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &::placeholder {
    color: ${colors.gray[150]};
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const NumberArrows = styled.div`
  position: absolute;
  right: 1em;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1em 0;
  gap: 0.3em;
  font-size: ${fontSize[1]};
`;
