import border from "./border";
import boxShadow from "./box-shadows";
import colors from "./colors";
import fontSize from "./font-size";
import fontStyle from "./font-style";
import layout from "./layout";
import margin from "./margins";
import padding from "./paddings";
import text from "./text";

export {
  boxShadow,
  colors,
  fontSize,
  layout,
  margin,
  padding,
  text,
  fontStyle,
  border,
};
