import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { setCookie } from "cookies-next";
import { useFormik } from "formik";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

import Button from "@components/Button";
import FloatingLabelInput from "@components/FloatingLabelInput";
import Typography from "@components/Typography";
import colors from "@variables/colors";
import fontSize from "@variables/font-size";
import fontStyle from "@variables/font-style";
import layout from "@variables/layout";
import margin from "@variables/margins";
import padding from "@variables/paddings";
import Globals from "src/assets/styles/globals";
import ApiContext from "src/lib/contexts/ApiContext";
import LoginSchema from "src/lib/ValidationSchema/LoginSchema";

import { appToast } from "./_app";

const LoginPage = () => {
  const router = useRouter();
  const { apiClient, setUser } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);

  const getInitialValues = () => {
    return {
      email: "",
      password: "",
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });

  async function handleSubmit() {
    setLoading(true);
    const requestData = formik.values;

    try {
      const res = await apiClient.auth(requestData);
      setCookie(`${process.env.NEXT_PUBLIC_TOKEN_KEY}`, res.accessToken, {
        maxAge: 3600 * 24 * 7 * 8,
      });
      setUser(res);
      localStorage.removeItem("cal-vw");
      localStorage.removeItem("cal-month");
      localStorage.removeItem("cal-week");
      localStorage.removeItem("cal-day");
      router.push({
        pathname: "/",
        query: router.query,
      });
    } catch (err: any) {
      appToast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Wrapper>
      <Head>
        <title>Login</title>
      </Head>
      <Globals />
      <Logo>
        <Image
          src={"/LOGO WITHOUT TYPE.png"}
          alt="dendots"
          width={"144"}
          height={"144"}
          objectFit="contain"
        />
      </Logo>
      <LoginForm onSubmit={formik.handleSubmit}>
        <LoginTitle as="h1" content="LOGIN" />
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 0.8em;
            & > * {
              width: 100%;
            }
          `}
        >
          <div>
            <FloatingLabelInput
              withborder={true}
              label="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email ? formik.errors.email : ""}
            />
          </div>
          <div>
            <FloatingLabelInput
              withborder={true}
              type="password"
              label="Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password ? formik.errors.password : ""}
            />
          </div>

          <Link href={"/change-password"} css={forgetPasswordLink}>
            Forget password?
          </Link>
        </div>
        <Button
          type="submit"
          label="Login"
          theme="primary"
          css={loginButtonCss}
          loading={loading}
        />

        <SignUpText>
          Do not you have an account?
          <a
            onClick={() =>
              router.push({
                pathname: "/signup",
                query: router.query,
              })
            }
          >
            Sign Up
          </a>
        </SignUpText>
      </LoginForm>
    </Wrapper>
  );
};

export default LoginPage;

const Wrapper = styled.div`
  padding: 2em;
  min-height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoginTitle = styled(Typography)`
  text-align: center;
  font-family: ${fontStyle.family.montserrat};
  font-size: ${fontSize[7]};
  font-weight: ${fontStyle.weight.bold};
  padding-bottom: ${padding[6]};
  color: ${colors.gray[800]};
`;

const LoginForm = styled.form`
  border: 1px solid #cecece;
  border-radius: 20px;
  padding: 3em;
  width: 460px;
  max-width: 80%;
  ${layout.mediaBreakpointDown("lg")} {
    max-width: 100%;
    padding: 1.5em;
  }
`;

const forgetPasswordLink = css`
  cursor: pointer;
  font-family: ${fontStyle.family["montserrat"]};
  font-size: ${fontSize[3]};
  color: ${colors.blue[500]};
`;

const loginButtonCss = css`
  width: 100%;
  margin-top: 2.5em;
`;

const SignUpText = styled.div`
  font-family: ${fontStyle.family["montserrat"]};
  font-size: ${fontSize[1]};
  color: ${colors.gray[400]};
  padding-top: ${padding[9]};
  a {
    color: ${colors.blue[500]};
    padding: 0 0.5em;
    cursor: pointer;
  }
`;

const Logo = styled.div`
  width: fit-content;
  margin: 0 auto ${margin[10]};
  ${layout.mediaBreakpointDown("lg")} {
    width: 35%;
  }
`;
