import { css, SerializedStyles } from "@emotion/react";

import colors from "@variables/colors";
import fontSize from "@variables/font-size";

interface Props {
  message: string;
  css?: SerializedStyles;
}

export default function ValidationError({ message, ...otherProp }: Props) {
  const style = css`
    font-size: ${fontSize[1]};
    padding: 0.2em 0.5em;
    color: ${colors.red[500]};
  `;
  return (
    <div css={style} {...otherProp}>
      {message}
    </div>
  );
}
