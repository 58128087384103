import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { fontStyle, fontSize, padding, border, colors } from "@variables/index";
import Icon from "src/components/Icon";

type ButtonTheme = "primary" | "danger" | "success" | "warn" | "default";
type ButtonSize = "small" | "normal" | "large";

interface Props {
  children?: React.ReactNode;
  label?: string;
  theme?: ButtonTheme;
  type?: "button" | "submit" | "reset" | undefined;
  size?: ButtonSize;
  icon?: string;
  iconSize?: string;
  disabled?: boolean;
  css?: SerializedStyles;
  iconcolor?: string;
  onClick?: any;
  loading?: boolean;
}

type ButtonThemeObject = {
  color: string;
  bg: string;
  borderColor: string;
  shadow: string;
};

const buttonThemes: { [key in ButtonTheme]: ButtonThemeObject } = {
  warn: {
    color: colors.white,
    bg: colors.yellow[100],
    borderColor: colors.yellow[100],
    shadow: colors.yellow[100],
  },
  success: {
    color: colors.white,
    bg: colors.green[100],
    borderColor: colors.green[100],
    shadow: colors.green[100],
  },
  primary: {
    color: colors.white,
    bg: colors.blue[500],
    borderColor: colors.blue[500],
    shadow: colors.blue[300],
  },
  danger: {
    color: colors.white,
    bg: colors.red[500],
    borderColor: colors.red[500],
    shadow: colors.red[500],
  },
  default: {
    color: colors.gray[800],
    bg: colors.white,
    borderColor: colors.gray[200],
    shadow: colors.gray[50],
  },
};

const buttonSizes: { [key in ButtonSize]: string } = {
  small: fontSize[1],
  normal: fontSize[3],
  large: fontSize[5],
};

const Button = (props: Props) => {
  const {
    children,
    theme = "default",
    type,
    size,
    label,
    icon,
    iconSize,
    disabled,
    iconcolor,
    loading = false,
    ...otherProps
  } = props;
  const currentTheme = buttonThemes[theme];

  const Wrapper = styled.button<{
    theme?: { [key: string]: string };
    size?: string;
  }>`
    .icon {
      color: ${iconcolor};
    }
    font-family: ${fontStyle.family["montserrat"]};
    height: fit-content;
    gap: 0.63rem;
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.color};
    border: ${border.width[2]} solid ${(props) => props.theme.borderColor};
    display: flex;
    justify-content: ${icon ? "space-between" : "center"};
    align-items: center;
    font-size: ${(props) => props.size};
    font-weight: ${fontStyle.weight["medium"]};
    text-transform: capitalize;
    padding: ${padding[7]};
    border-radius: ${border.radius[4]};
    background: ${(disabled || loading) &&
    theme !== "default" &&
    colors.gray[400]};
    color: ${(disabled || loading) && theme !== "default" && colors.white};
    border-color: ${(disabled || loading) && colors.gray[400]};
    position: relative;

    &:focus {
      box-shadow: 0 0 0 0.2rem ${(props) => props.theme.shadow};
    }

    &:hover {
      opacity: 0.9;
    }
  `;

  const Spinner = styled.div`
    /* position: absolute; */
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    right: 50%;
    top: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-left-color: #fff;
    border-top-color: #fff;
    display: ${loading ? "block" : "none"};
    animation: ${loading && "spin 0.6s infinite linear"};

    @keyframes spin {
      to {
        transform: rotate(359deg);
      }
    }
  `;

  return (
    <Wrapper
      type={type ?? "button"}
      theme={currentTheme}
      size={buttonSizes[size || "normal"]}
      disabled={disabled || loading}
      {...otherProps}
    >
      {loading ? <Spinner /> : label}
      {icon && (
        <div>
          <Icon name={icon} size={iconSize} />
        </div>
      )}
      {children}
    </Wrapper>
  );
};

export default Button;
