const text = {
  lineHeight: {
    1: "0.125em",
    2: "0.25em",
    3: "0.375em",
    4: "0.5em",
    5: "0.75em",
    6: "0.875em",
    7: "1em",
    8: "1.125em",
    9: "1.375em",
    10: "1.5em",
  },
  letterSpacing: {
    1: "0.125em",
    2: "0.25em",
    3: "0.375em",
    4: "0.5em",
    5: "0.75em",
    6: "0.875em",
    7: "1em",
    8: "1.125em",
    9: "1.375em",
    10: "1.5em",
  },
};

export default text;
